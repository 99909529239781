var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("q-footer", { attrs: { elevated: "" } }, [
    _c(
      "div",
      {
        staticClass: "fixed-bottom footer shadow-9 q-pa-sm",
        staticStyle: { "background-color": "#f0f0f0", color: "#000" },
        attrs: { align: "right" },
      },
      [_vm._v(" (C) AbyBroker S.r.l. - Tutti i diritti riservati ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }